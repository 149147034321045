import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import jewellery from "../../Assets/Projects/jewellery.png";
import translator from "../../Assets/Projects/translator.png";
import gatsby from "../../Assets/Projects/gatsby.png";
import hotel from "../../Assets/Projects/hotel.png";
import bitcoin from "../../Assets/Projects/bitcoin.png";
import todo from "../../Assets/Projects/todo.png";
import weather from "../../Assets/Projects/weather.png";
import thalify from "../../Assets/Projects/thalify.png";
import hoobank from "../../Assets/Projects/hoobank.png";
import sweldo from "../../Assets/Projects/sweldo.png";
import ecommerce from "../../Assets/Projects/ecommerce.png";
import wix from "../../Assets/Projects/wix.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sweldo}
              isBlog={false}
              title="Sweldo App"
              description="Employees are able to access and track the number of hours they have spent working, view their pay stubs and salary history, request time off, and manage their personal information all through a user-friendly online portal."
              ghLink="https://github.com/FireVoltHero/capstone-sweldo-app"
              demoLink="https://sweldo-app.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ecommerce}
              isBlog={false}
              title="Ecommerce Project"
              description="This is a React-based e-commerce shopping project. MockAPI is used to get card information, and goods may be added to or deleted from the shopping cart or favorites area. User authentication is handled by Firebase, payment is handled by Stripe, and form validation is handled by Formik and yup."
              ghLink="https://github.com/FireVoltHero/e-commerce-react"
              demoLink="https://more-shopping.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={wix}
              isBlog={false}
              title="website-www.ssnembs.wixsite.com-healthware"
              description="SSN IEEE EMBS is a student branch which conducts lots of events like webinars, workshops, hackathon etc.. I am the student chair for SSN IEEE EMBS."
              ghLink="https://github.com/FireVoltHero/gatsby-itekscope"
              demoLink="https://ssnembs.wixsite.com/healthware"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jewellery}
              isBlog={false}
              title="Jewellery Marketing APP"
              description="Jewellery Marketing app using typescript, javascript, html and CSS."
              ghLink="https://github.com/FireVoltHero/JewelleryMiniApp-Angular"
              demoLink="https://jewellery-app.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={translator}
              isBlog={false}
              title="Translator Application"
              description="This application helps to translate from one language to multiple languages."
              ghLink="https://github.com/FireVoltHero/translator-mono-repo"
              demoLink="https://translator-application.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gatsby}
              isBlog={false}
              title="Gatsby Itekscope"
              description="Itekscope is a art application whether is your passion or profession, you've come to the right place."
              ghLink="https://github.com/FireVoltHero/gatsby-itekscope"
              demoLink="https://gatsby-itekscope.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hotel}
              isBlog={false}
              title="Vue Typescript Hotel Project"
              description="This is designed to serve as a hotel booking platform that allows users to search for available rooms, view room details, and make reservations."
              ghLink="https://github.com/FireVoltHero/vue-typescript-hotel-project"
              demoLink="https://vue-hotel-management.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitcoin}
              isBlog={false}
              title="Cryptocurrency Bitcoin Price Chart with Vite"
              description="Cryptocurrency Bitcoin Price Chart was developed using React, Typescript, coingecko-api, react-chartjs-2, chart.js and tailwindcss."
              ghLink="https://github.com/paalamugan/cryptocurrency-bitcoin-price-chart"
              demoLink="https://cryptocurrency-bitcoin.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todo}
              isBlog={false}
              title="Todo With React"
              description="A simple todo app built with Appwrite and React."
              ghLink="https://github.com/FireVoltHero/first-app-write-react-main"
              demoLink="https://appwrite-todo-with-react.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={weather}
              isBlog={false}
              title="React Weather Application"
              description="Find the current weather and 7 days forecast of any city on earth with this simple little web app."
              ghLink="https://github.com/FireVoltHero/React-Weather-App"
              demoLink="https://monitor-weather.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={thalify}
              isBlog={false}
              title="Thallify.com"
              description="Sharing your top artists\tracks on social media made easier."
              ghLink="https://github.com/FireVoltHero/thallify.com"
              demoLink="https://thallify.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hoobank}
              isBlog={false}
              title="HooBank Landing Page"
              description="This is a landing page for HooBank, a fictional app whose gorgeous design was created by FireVoltHero and completed by their community over at Figma."
              ghLink="https://github.com/FireVoltHero/hoobank"
              demoLink="https://hoobank-rwxdan.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
